<template>
    <v-menu
        dense
        width="150">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="openMenu"
                :class="buttonClass"
                class="v-step-help"
                icon
                v-bind="attrs"
                v-on="on"
                title="Help">
                <v-icon>mdi-help-circle</v-icon>
            </v-btn>
        </template>

        <v-list min-width="350">
            <v-subheader v-if="$canView('help-links')">
                Custom Help
                <v-spacer />
                <BT-Dialog 
                    v-if="$canEdit('help-links')"
                    :getOnOpen="getNewCustomLink"
                    label="Custom Link"
                    @ok="addOrUpdateCustomLink"
                    width="350">
                    <template v-slot="{ item }">
                        <BT-Field-String
                            v-model="item.title"
                            label="Title"
                            isEditing />
                        
                        *Must start with https:// or http://
                        <BT-Field-String
                            v-model="item.url"
                            label="Link URL"
                            isEditing />
                    </template>
                </BT-Dialog>
                <!-- <v-btn v-if="$canEdit('help-links')" small icon>
                    <v-icon>mdi-plus</v-icon>
                </v-btn> -->
            </v-subheader>
            <v-list-item v-for="(customLink, index) in customLinks" :key="index + 'a'" @click="openURL(customLink.url)" dense>
                <v-list-item-icon>
                    <v-icon small>mdi-help-rhombus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ customLink.title || 'Custom Help' }}</v-list-item-title>
                    <v-list-item-subtitle>{{ customLink.url }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="$canEdit('help-links')">
                    <v-btn small icon @click.stop="deleteCustomLink(customLink.id)"><v-icon small>mdi-delete</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>

            <v-divider v-if="isLengthyArray(tours)" />
            <v-subheader v-if="isLengthyArray(tours)">Page Tours</v-subheader>
            <v-list-item v-if="$tourExists('all')" key="-1b" dense @click="$startTour('all' + ($BlitzIt.navigation.session.appNavigation ? '.app-sidebar' : ''))">
                <v-list-item-icon>
                    <v-icon small>mdi-help-network</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ 'Tour The App Interface' }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(tour, index) in tours" :key="index + 'b'" dense @click="$startTour(tour.id)">
                <v-list-item-icon>
                    <v-icon small>mdi-help-network</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ tour.title || 'Tour This Page' }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="isLengthyArray(training)" />
            <v-subheader v-if="isLengthyArray(training)">Training Tools</v-subheader>
            <v-list-item v-for="(trainer, index) in training" :key="index + 'c'" dense :to="{ name: trainer.trainingNavigation }">
                <v-list-item-icon>
                    <v-icon small>mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ trainer.title || 'Train' }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider v-if="isLengthyArray(clips)" />
            <v-subheader v-if="isLengthyArray(clips)">Videos</v-subheader>
            <v-list-item v-for="(clip, index) in clips" :key="index + 'd'" target="_blank" :href="'https://www.youtube.com/watch?v=' + clip.clipID">
                <v-list-item-icon>
                    <v-avatar size="50" tile>
                        <img :src="'https://img.youtube.com/vi/' + clip.clipID + '/hqdefault.jpg'" height="30" />
                    </v-avatar>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ clip.title || 'How To' }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <BT-Snack v-model="msg" />
        </v-list>

        <v-overlay :value="loadingMsg != null" absolute key="6" class="text-center">
            <v-progress-circular indeterminate size="32" />
            <p>{{ loadingMsg }}</p>
        </v-overlay>
    </v-menu>
    
</template>

<script>

export default {
    name: 'BT-Assistant',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            customLinks: [],
            helpObject: null,
            loadingMsg: null,
            msg: null,
            steps: [],
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: ''
        }
    },
    computed: {
        clips() {
            return this.helpObject != null && this.isLengthyArray(this.helpObject.clips) ? this.helpObject.clips : [];
        },
        helpExists() {
            return this.isLengthyArray(this.clips) || this.isLengthyArray(this.tours) || this.isLengthyArray(this.customLinks);
        },
        tours() {
            return this.helpObject != null && this.isLengthyArray(this.helpObject.tours) ? this.helpObject.tours : [];
        },
        training() {
            return this.helpObject != null && this.isLengthyArray(this.helpObject.training) ? this.helpObject.training : [];
        },
    },
    watch: {
        '$route': function(v) {
            this.resetOptionsForRoute(v);
        }
    },
    methods: {
        async addOrUpdateCustomLink(res) {
            try {
                if (res.id == null) {
                    //create
                    this.loadingMsg = 'Creating Link';
                    var result = await this.$BlitzIt.store.post('help-links', res);
                    this.customLinks.push(result);
                }
                else {
                    //update
                    this.loadingMsg = 'Updating Link';
                    var r = await this.$BlitzIt.store.patch('help-links', res);
                    res.rowVersion = r.rowVersion;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async deleteCustomLink(id) {
            try {
                this.loadingMsg = 'Removing Link';
                await this.$BlitzIt.store.delete('help-links', id, null);
                this.customLinks = this.customLinks.filter(x => x.id != id);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        getNewCustomLink() {
            return {
                id: null,
                rowVersion: null,
                tags: this.$route.name,
                title: null,
                url: null,
            }
        },
        openURL(url) {
            if (url != null) {
                console.log('pening');
                window.open(url, '_blank');
            }
        },
        async openMenu() {
            console.log('opening menu');
            
            try {
                this.loadingMsg = 'Finding Info';
                
                await this.$BlitzIt.help.loadData();

                this.customLinks = await this.$BlitzIt.store.getAll('help-links', { tags: this.$route.name }, false, null, null);
                
                this.resetOptionsForRoute(this.$route);

            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        resetOptionsForRoute(route) {
            if (route != null) {
                var navs = [route.name]; //, 'all'];

                this.helpObject = this.$BlitzIt.help.findHelpByNavigation(navs);
            }
        },
        // startTour(tour) {
        //     this.$emit('changeTour', tour);

        //     this.$tours['tour'].steps = tour.steps;
        //     this.$tours['tour'].start();
        //     // var tName = tour.title || 'Tour This Page';
        //     // this.$tours[tName].start();
        // }
    }
}
</script>